<template>
    <v-bottom-navigation
        dark
        horizontal
        height=40
    >
        <v-btn
            text
            color="white"
            target="_blank"
            :href="impressumLinkExtern"
        >
            <span>Impressum</span>
        </v-btn>

        <download-form-widget
            :jwt="jwt"
            target-document="datenschutz"
            is-btn
        >
            {{$t("message.footer.datenschutz")}}
        </download-form-widget>
    </v-bottom-navigation>
</template>

<script>
import Vue from "vue";
import DownloadFormWidget from "@/widgets/DownloadFormWidget.vue";

export default Vue.extend({
    name: "RoboFooter",
    components: {
        DownloadFormWidget,
    },
    props: {
        phone: String,
        jwt: {
            type: String,
            required: false,
            default: () => ''
        }
    },
    computed: {
        // TODO: this is just temporary, replace with real impressum fetched via Evolution
        impressumLinkExtern() {
            let depotstandHost = window.location.host.replace(/(\.anlegen\.depotstand|\.anlegen)/, '.depotstand')
            if (depotstandHost.startsWith("reuss.")) {
                // Die reuss-Subdomain soll ein Impressum der Reuss verlinken, z. Bsp. "reussprivate"
                depotstandHost = 'reussprivate' + depotstandHost.substring(5);
            }
            let depotstandUrl = new URL(`https://${depotstandHost}`)
            depotstandUrl.port = ''
            return `${depotstandUrl}impressum.php`
        },
    },
});
</script>
