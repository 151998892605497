export const messages = {
    de: {
        currency: {
            style: 'currency',
            currency: 'EUR',
        },
        message: {
            landingPage: {
                hello1: 'Hallo',
                hello2: 'Investor.',
                beschreibung1: 'Prospero ist der digitale Vermögensberater für Menschen, die sich nicht mit durchschnittlichen Anlagelösungen zufrieden geben.\n' +
                    'Für Menschen, die mehr verlangen. Menschen wie Sie.',
                beratungStarten: 'JETZT STARTEN',
                title1: 'Wir sind ein neuer Typ Investment-Berater.',
                beschreibung2: 'Menschen zahlen zu viel Geld oder verbringen zu ' +
                    'viel Zeit damit, durchschnittliche Investmentmöglichkeiten ' +
                    'zu finden. Wir gehen die extra Meile. Wir machen ' +
                    'hochentwickelte Investmentstrategien und moderne ' +
                    'Technologie, die bisher Profis vorbehalten waren, ' +
                    'für Sie zugänglich. Unsere persönliche Beratung ' +
                    'unterstützt Sie zusätzlich dabei, das Beste aus Ihrem Geld zu machen.',
                stichpunkt1: 'Unsere Technologie hilft Ihnen, auf Kurs zu bleiben.',
                stichpunkt2: 'Wenn Sie mal sprechen möchten - unsere Berater sind für Sie da',
                stichpunkt3: 'Eine günstige Jahresgebühr - keine extra Kosten',
                stichpunkt4: 'Keine Mindestanlagesummen',
                title2: 'Wir helfen Ihnen, Ihre Finanzplanung in den Griff zu bekommen.',
                beschreibung3: 'Egal ob Sie neu oder ein erfahrener Profi sind,' +
                    ' Prospero macht das Richtige für Sie aus Ihrem Geld. ',
                beschreibung4: 'Unser Ziel ist es, Ihnen ein finanziell abgesichertes ' +
                    'Leben zu ermöglichen. Das erreichen wir, indem wir als digitaler ' +
                    'Vermögensverwalter Ihre Finanzanlagen optimal managen, angepasst ' +
                    'auf Ihre individuellen Lebensziele, 24 Stunden am Tag, 7 Tage die Woche.',
                cookiesText: 'Für die Funktionalität unserer Seite setzen wir Cookies. ' +
                    'Weitere Details entnehmen Sie bitte unserer',
                datenschutzerklaerung: 'Datenschutzerklärung',
                akzeptieren: 'Akzeptieren',

            },
            kundentyp: {
                title: 'Einzel- oder Gemeinschaftsdepot eröffnen',
                subtitle: 'Personen ab 18 Jahren haben die Möglichkeit, bei uns ein Depot eröffnen. ' +
                  'Dieses Angebot richtet sich ausschließlich an Privatpersonen.',
                einzeldepot: 'Einzeldepot',
                beschreibungEDepot: 'Eröffnen Sie ein Depot für sich selbst.',
                gemeinschaftsdepot: 'Gemeinschaftsdepot',
                beschreibungGDepot: 'Eröffnen Sie ein gemeinsames Depot für sich und eine weitere Person.',
            },
            dienstleistung: {
                title: 'Willkommen! Wie möchten Sie starten?',
                subtitle: 'Ihr erster Schritt auf dem Weg ein besserer Investor zu werden.',

                vermoegensverwaltungTitle: 'Vermögensverwaltung',
                vermoegensverwaltungSubtitle: 'Wir übernehmen für Sie die Anlage in aktiv gemanagte Investmentfonds und ETFs. ' +
                    'Das Risiko wird auf Ihre Bedürfnisse abgestimmt und breit gestreut. ' +
                    'Ihr Vermögen wird aktiv betreut um Risiken zu vermeiden und Chancen am Kapitalmarkt zu erkennen.',
                vermoegensverwaltung: 'Sie legen Ihr Geld in ' +
                    'aktiv gemanagte Investmentfonds sowie ETFs an. Die Fondsvermögensverwaltung bietet Ihnen eine ' +
                    'sorgfältig ausgewogene Mischung aus Investmentfonds – mit einer auf Ihre Bedürfnisse abgestimmte ' +
                    'breiten Risikostreuung. Jede Strategie wird aktiv betreut, um Risiken zu vermeiden und Chancen ' +
                    'am Kapitalmarkt zu erkennen. Der Vermögensverwalter übernimmt hierbei für Sie die Anlage und ' +
                    'entscheidet in eigener Verantwortung über Umschichtungen oder Verkäufe.',
                vermoegensverwaltungAuswaehlen: 'Weiter mit der Vermögensverwaltung',

                fondsPortfolioTitle: 'Fonds-Portfolio',
                fondsPortfolio: 'Sie legen ' +
                    'Ihr Geld in aktiv gemanagte Investmentfonds sowie ETFs an. Auch diese ' +
                    'Lösung bietet Ihnen zum Start eine sorgfältig zusammengestellte Mischung aus ' +
                    'Investmentfonds. Ob Sie Ihr Portfolio danach einfach laufen lassen, oder Ihre gewählte ' +
                    'Anlagestrategie später anpassen, entscheiden Sie selbst. Hier gibt es nach ' +
                    'erfolgter Investition keine aktive Steuerung Ihrer Investition.',
            },
            resetmodal: {
                titel: 'Ihre Angaben werden gelöscht',
                beschreibung: 'Beim Verlassen der Onlineberatung werden die bisher gemachten Angaben gelöscht. ',
                weiter: 'Beratung verlassen',
                abbrechen: 'abbrechen',
            },
            logout: {
                buttonTitel: 'Startseite',
                titel: 'Angaben verwerfen',
                beschreibung: 'Ihre aktuelle Sitzung wird verworfen und Sie werden zur Startseite weitergeleitet.' +
                  '<br><br>' +
                  'Hinweis: Sie können über den in Ihrer Registrierungs-E\u2011Mail enthaltenen Link wieder zu ' +
                  'Ihrem letzten Stand zurückkehren.',
                weiter: 'verwerfen und zur Startseite',
                abbrechen: 'abbrechen',
            },
            login: {
                title: 'Login',
                loginButton: 'Einloggen',
                text: 'Ihr neuer Anmeldecode wurde  an Ihre Mobilnummer +49 123 *** 90 verschickt.',
                nochNichtKunde: 'Sie sind noch nicht Kunde?',
                anlagevorschlagErstellen: 'Anlagevorschlag erstellen'
            },
            bestaetigunganlageberatung: {
                title: 'Bestätigungen für die Vertragserstellung',
                box1: {
                    ek: 'Ich bestätige, dass ich die nachfolgenden Dokumente zur Verfügung gestellt bekommen habe und mit dem Inhalt einverstanden bin:',
                    gk: 'Wir bestätigen, dass wir die nachfolgenden Dokumente zur Verfügung gestellt bekommen haben und mit dem Inhalt einverstanden sind:',
                },
                box2: {
                    ek: 'Ich beauftrage die ',
                    gk: 'Wir beauftragen die ',
                },
                box2_1: {
                    ek: ' mit der Verwaltung meines Anlagevermögens gemäß den',
                    gk: ' mit der Verwaltung unseres Anlagevermögens gemäß den',
                },
                boxvv: {
                    ek: 'Ich beauftrage die Reuss Private Bank für Wertpapierhandel AG mit der Verwaltung meines Anlagevermögens gemäß den',
                    gk: 'Wir beauftragen die Reuss Private Bank für Wertpapierhandel AG mit der Verwaltung unseres Anlagevermögens gemäß den',
                },
                textanlagevorschlag: 'Anlagevorschlag',
                rechtliche_dokumente: 'Produktinformationsblätter',
                factsheet: 'Factsheet',
                agb: "Allgemeine Kundeninformation",
                kosteninformation: 'Kosteninformation',
                textallgemeinegeschaeftsbedingung: 'Allgemeine Geschäftsbedingungen der Anlageberatung',
                textwiderrufsbelehrung: 'Widerrufsbelehrung',
                textvertragsbedingung: 'Vertragsbedingungen der ',
                textvertragsbedingungreuss: 'Vertragsbedingungen der Reuss Private Bank für Wertpapierhandel AG',
                downloadbutton: 'ALLE DOKUMENTE HERUNTERLADEN',
                notiz: 'Die Dokumente werden nach der Beauftragung auch in Ihrer Postbox bereitgestellt.'
            },
            depoteroeffnung: {
                title: 'Depoteröffnungsantrag',
                info: 'Bitte überprüfen Sie den Inhalt des Depoteröffnungsantrags auf Richtigkeit.',
                downloadbutton: 'Depoteröffnungsantrag ansehen',
                bestaetigung: {
                    ek: 'Der Depoteröffnungsantrag mit Kauf- und Sparplanaufträgen entspricht meinem Anlagewunsch.',
                    gk: 'Der Depoteröffnungsantrag mit Kauf- und Sparplanaufträgen entspricht unserem Anlagewunsch.',
                },
                bestaetigungVv: {
                    ek: 'Der Depoteröffnungsantrag entspricht meinem Anlagewunsch.',
                    gk: 'Der Depoteröffnungsantrag entspricht unserem Anlagewunsch.',
                },
            },
            bestaetigungdepoteroeffnung: {
                title: 'Bestätigungen für die Depoteröffnung',
                erklaerung1: 'Als Depotstelle für Ihre Anlage dient die ',
                erklaerung2: ' Die folgenden Vereinbarungen werden zwischen Ihnen und der Depotstelle abgeschlossen.',
                subtitle: 'Allgemeine Bedingungen',
                checkbox: {
                    ca: {
                        ek: 'Ich stimme den allgemeinen Bedingungen der Depoteröffnung zu.',
                        gk: 'Wir stimmen den allgemeinen Bedingungen der Depoteröffnung zu.',
                    },
                    pd: {
                        ek: 'Ich stimme den Bedingungen der Datenweitergabe zu.',
                        gk: 'Wir stimmen den Bedingungen der Datenweitergabe zu.',
                    },
                    row: {
                        ek: 'Ich stimme den Bedingungen des Widerrufsrechts zu.',
                        gk: 'Wir stimmen den Bedingungen des Widerrufsrechts zu.',
                    },
                    sdt: {
                        ek: 'Ich stimme den Bedingungen des SEPA-Lastschriftmandats zu.',
                        gk: 'Wir stimmen den Bedingungen des SEPA-Lastschriftmandats zu.',
                    },
                    se: {
                        ek: 'Ich stimme den Bedingungen des Serviceentgelts zu.',
                        gk: 'Wir stimmen den Bedingungen des Serviceentgelts zu.',
                    },
                    ma: {
                        ek: 'Ich stimme den Bedingungen der Vermögensverwaltungsvollmacht zu.',
                        gk: 'Wir stimmen den Bedingungen der Vermögensverwaltungsvollmacht zu.',
                    },
                    ee: {
                        ek: 'Ich stimme dem Einzug des Einstiegsentgelts des Vermögensverwalters durch die FFB zu.',
                        gk: 'Wir stimmen dem Einzug des Einstiegsentgelts des Vermögensverwalters durch die FFB zu.',
                    },
                    pa: {
                        ek: 'Ich stimme den Bedingungen des Portfolioantrags zu.',
                        gk: 'Wir stimmen den Bedingungen des Portfolioantrags zu.',
                    },
                    tra: {
                        ek: 'Ich beauftrage die Portfolio-Transaktionen.',
                        gk: 'Wir beauftragen die Portfolio-Transaktionen.',
                    },
                    vld: {
                        ek: 'Ich stimme der Übermittlung der nach dem Vermögensbildungsgesetz erforderlichen Daten an das Bundeszentralamt für Steuern zu.',
                        gk: 'Wir stimmen der Übermittlung der nach dem Vermögensbildungsgesetz erforderlichen Daten an das Bundeszentralamt für Steuern zu.',
                    },
                    bopt: {
                        ek: 'Ich beantrage die Kauf- und Sparplanaufträge.',
                        gk: 'Wir beantragen die Kauf- und Sparplanaufträge.',
                    },
                },
                downloadbutton: 'ALLE DOKUMENTE HERUNTERLADEN',
                weiterbutton: 'Kostenpflichtig beantragen',
                zurueckbutton: 'Zurück',
            },
            fragen: {
                title: 'Fragen gemäß Geldwäschegesetz',
                erklaerung: 'Gemäß des Geldwäschegesetzes sind wir verpflichtet zu prüfen, ob es sich bei Ihnen um eine ' +
                    '„Politisch exponierte Person“ handelt und ob Sie die alleinige wirtschaftlich berechtigte Person im Bezug auf die Anlage sind.',
                info: 'Als politisch exponierte Person (PEP) gelten alle natürlichen Personen, die im In- oder Ausland ein wichtiges öffentliches Amt ausüben oder in den letzten zwölf Monaten ausgeübt haben.',
                info2: 'Als PEP gelten auch die unmittelbaren Familienangehörigen der oben genannten Personengruppe und sonstige nahestehende Personen.',
                box1: 'Ich habe die Definition der PEP verstanden und bestätige, dass ich keine PEP im Sinne des Geldwäschegesetzes bin.',
                box2: {
                    ek: 'Ich erkläre, dass ich die alleinige wirtschaftlich berechtigte Person ' +
                      'im Bezug auf die Anlage bin.',
                    gk: 'Ich erkläre, dass ich zusammen mit {0} die alleinige wirtschaftlich berechtigte Person ' +
                      'im Bezug auf die Anlage bin.',
                },
                tooltipPep: `<p>Beispiele – nicht abschließend – für PEP:</p>
                    <ul>
                        <li>Staats- und Regierungschefs</li>
                        <li>Bundes- und Landesminister, deren Stellvertreter und vergleichbare Positionen im Ausland und auf EU-Ebene</li>
                        <li>Parlamentsangehörige des Europaparlaments, des Bundestages, der Landtage und vergleichbarer ausländischer Zusammensetzungen</li>
                        <li>Staats- und Regierungschefs</li>
                        <li>Staatssekretäre</li>
                        <li>Führungsgremien von politischen Parteien</li>
                        <li>Oberste Richter, Diplomaten und hochrangige Militärs</li>
                        <li>Verwaltungs-, Leitungs- und Aufsichtsorgane</li>
                        <ul>
                            <li>Staatseigener Unternehmen</li>
                            <li>Zwischenstaatlicher Organisationen</li>
                            <li>von Rechnungshöfen und Zentralbanken</li>
                        </ul>
                    </ul>
                `,
                tooltipUnmittelbare: `
                    <p>Als unmittelbare Familienangehörige gelten die Eltern, Ehepartner, Kinder und Schwiegerkinder.</p>
                    <p>Eine bekanntermaßen nahestehende Person ist eine natürliche Person,</p>
                    <ul>
                        <li>die gemeinsam mit einer PEP wirtschaftlich Berechtigter einer juristischen Person oder einer sonstigen Rechtsgestaltung ist</li>
                    </ul>
                    <p>oder</p>
                    <ul>
                        <li>die alleinig wirtschaftlich Berechtigter einer juristischen Person oder einer sonstigen Rechtsgestaltung ist, deren Errichtung faktisch zugunsten einer PEP erfolgte</li>
                    </ul>
                    <p>oder</p>
                    <ul>
                        <li>die zu einer PEP sonstige enge Geschäftsbeziehungen unterhält.</li>
                    </ul>
                `,
            },
            Geeignetheitserklaerung: {
                title: 'Geeignetheitserklärung',
                erklaerung: 'Eine detaillierte Darstellung, inwiefern Ihre Angaben bei unserem Vorschlag berücksichtigt wurden, finden Sie im folgenden Dokument,' +
                    ' welches wir Ihnen auch am Ende mit den weiteren Vertragsdokumenten zum Download anbieten.',
                download: 'Geeignetheitserklärung herunterladen',

            },
            anlagerichtlinien: {
                title: 'Anlagerichtlinien',
                erklaerung: 'TODO',
                download: 'Anlagerichtlinien herunterladen',
            },
            fehler: {
                title: 'Onlineberatung nicht möglich',
                erklaerung: 'Eine Anlage bringt Risiken mit sich und erfordert daher entsprechende Kenntnisse.',
                notiz1: 'Eine Anlage bringt Risiken mit sich und erfordert daher entsprechende Kenntnisse. ',
                notiz2: 'Nehmen Sie gerne Kontakt zu uns auf, um Informationen über weitere Möglichkeiten ' +
                    'einer Anlage außerhalb der Onlineberatung zu erhalten.',
            },
            beratungsende: {
                title: 'Onlineberatung beendet',
                notiz1: 'Die im Rahmen der Onlineberatung von Ihnen gemachten Angaben wurden gelöscht.',
                notiz2: 'Nehmen Sie gerne Kontakt zu uns auf, um Informationen über weitere Möglichkeiten einer Anlage außerhalb der Onlineberatung zu erhalten.',
            },
            postidentlater: {
                title: 'Onlineberatung verlassen',
                notiz1: 'Wir schicken Ihnen per E\u2011Mail einen Link, über den Sie den Identitätsnachweis später abschließen können.',
                notiz2: 'Sie können diese Seite schließen.',
            },
            inbearbeitung: {
                title: 'Depoteröffnung fehlgeschlagen',
                notiz1: 'Bei der Prüfung Ihres Depoteröffnungsantrags wurde von der Depotstelle ein Fehler gemeldet. ',
                notiz2: 'Grund dafür kann ein technisches Problem auf der Seite der Depotstelle sein.',
                notiz3: 'Wir benachrichtigen Sie in Kürze per E\u2011Mail über weitere Details.',
            },
            fehlereingabe: {
                title1: ' Es wurden Fehler in Ihren Eingaben gefunden',
                title2: '',
                notiz1: 'Bei der Prüfung Ihres Depoteröffnungsantrags wurden von der Depotstelle Fehler bei Ihren Eingaben gemeldet:',
                notiz2: 'Prüfen und korrigieren Sie Ihre Eingaben, um die weitere Bearbeitung Ihres Antrags zu ermöglichen.',
            },
            vollstaendigkeit: {
                title: 'Vollständigkeit und Korrektheit Ihrer Angaben',
                erklaerung: 'Bitte beachten Sie, dass geeignete Anlagemodelle nur dann vorgeschlagen werden ' +
                    'können, wenn die von Ihnen gemachten Angaben zutreffend sind und gewissenhaft gemacht ' +
                    'wurden. Andernfalls ist nicht gewährleistet, dass die Anlagevorschläge für Ihre persönlichen' +
                    ' Präferenzen passend sind.',
                box1: 'Ich bestätige die Vollständigkeit und Korrektheit der von mir gemachten Angaben.',
                herunterladen: 'Zusammenfassung herunterladen',
            },
            anlageziel: {
                title: 'Anlageziel',
                ab: {
                    title: 'Anlageziel - Wählen Sie Ihren Plan fürs Leben',
                    geeignetheitspruefung: 'Ergebnis der Geeignetheitsprüfung',
                    risikofreiText1: 'Investitionen am Kapitalmarkt sind Schwankungen unterworfen. Selbst bei ' +
                      'sicherheitsorientierten Investmentfonds ist das Risiko eines Teil- oder im schlimmsten ' +
                      'Fall sogar eines Totalverlustes zwar geringer als bei Produkten mit einem höheren Risiko, es ' +
                      'kann aber nie gänzlich ausgeschlossen werden.',
                    risikofreiText2: 'Für das von Ihnen gewählte Anlageziel "Vermögen <b>risikofrei</b> anlegen" ' +
                      'können wir Ihnen daher leider kein passendes Portfolio anbieten.',
                },
                vv: {
                    title: 'Welches Anlageziel Sie mit unserer Vermögensverwaltung verfolgen sollten',
                    bestaetigung: {
                      ek: 'Ich bestätige, dass mein Anlageziel mit dem Ziel der Vermögensverwaltung übereinstimmt.',
                      gk: 'Wir bestätigen, dass unser Anlageziel mit dem Ziel der Vermögensverwaltung übereinstimmt.',
                    },
                },

            },
            zeitraum: {
                title: 'Auswahl Anlagezeitraum',
                angabeZeitraum: 'Über welchen Zeitraum möchten Sie Ihr Geld anlegen?',
                angabeZeitraumTooltip: 'Als Grundsatz gilt, ein längerer Anlagezeitraum ist für eine ' +
                    'Geldanlage grundsätzlich gut.' +
                    '<br/>' +
                    '<br/>' +
                    'Bitte bedenken Sie, ob Sie den Anlagebetrag im gewählten Zeitraum, ' +
                    'etwa für eine geplante Anschaffung benötigen.' +
                    '<br/>' +
                    '<br/>' +
                    'Wenn der Anlagebetrag zu einem bestimmten Zeitpunkt fest benötigt wird, kann dies dazu führen, ' +
                    'dass zu diesem Zeitpunkt Kursverluste in Kauf genommen werden müssen.',
            },
            steuerpflicht: {
                title: 'Angaben zur Steuerpflicht',
                text: 'Bitte geben Sie alle Länder an, in denen Sie steuerpflichtig sind.',
                checkbox: 'Ich bestätige, dass ich nicht der US-Steuerpflicht unterliege.',
                weitere: 'Weitere Steuer-ID',
            },
            anlagebetrag: {
                title: 'Auswahl Anlagebeträge',
                angabeEinmalanlage: 'Welchen Betrag wollen Sie einmalig anlegen?',
                angabeSparbetrag: 'Welchen Betrag wollen Sie monatlich sparen?',
                angabeEinmalanlageTooltip: 'Wir empfehlen Ihnen, nicht Ihr gesamtes Vermögen zu investieren, sondern ' +
                    'immer eine ausreichende Liquiditätsreserve verfügbar zu halten.' +
                    '<br/>' +
                    '<br/>' +
                    'Wie hoch die Reserve im Einzelfall sein sollte, hängt von Ihren persönlichen Lebensumständen ' +
                    'ab. Als Ansatz empfehlen wir aber immer finanzielle Reserven in der Höhe Ihrer dreifachen ' +
                    'monatlichen Liquidität für unverhergesehene Ausgaben zurückzuhalten.',
                angabeSparbetragTooltip: 'Wir empfehlen Ihnen, immer eine monatliche Liquiditätsreserve ' +
                    'zurückzuhalten.' +
                    '<br/>' +
                    '<br/>' +
                    'Wie hoch die Reserve im Einzelfall sein sollte, hängt von Ihren persönlichen Lebensumständen ' +
                    'ab. Als Ansatz empfehlen wir aber immer mindestens 50% Ihrer monatlichen Liquidität ' +
                    'zurückzuhalten. ',
            },
            adresse: {
                title: 'Adresse',
            },
            risikobereitschaft: {
                title: 'Angabe Risikoneigung',
                auswahlRisikobereitschaft: 'Welches Risiko sind Sie bereit, zur Erreichung Ihrer Renditeziele' +
                    ' in Kauf zu nehmen?',
                tooltip: 'Eine Anlage in Finanzinstrumente kann bezogen auf Ihren Anlagebetrag zu Gewinnen und ' +
                    'Verlusten führen. Ihre Risikoneigung hat dabei Einfluss auf die zu erwartende positive, aber ' +
                    'auch mögliche negative Rendite.',
                risikoklasse1: 'Ihre Risikoklasse',
                wertverlust1: 'Produkte dieser Risikoklasse hatten in den vergangenen 5 Jahren eine durchschnittliche ' +
                    'jährliche Schwankungsbreite von',
                wertverlust2: '(Vergangenheitsbetrachtung, Schwankungen können in Zukunft höher sein).',
                sliderLabel1: 'Minimales<br>Risiko',
                sliderLabel2: 'Maximales<br>Risiko',
            },
            anlagevorschlag: {
                title: 'Anlagevorschlag',
                erwarteteEntwicklung: 'Erwartete Entwicklung',
                geeignetheits: 'Geeignetheitserklärung',
                geeignetheitsHerunterladen: 'Geeignetheitserklärung herunterladen',
                historischeEntwicklung: 'Historische Entwicklung',
                produkte: 'Produktverteilung',
                erwartungswertRendite: 'Erwartungswert Rendite',
                zusammensetzung: 'Zusammensetzung',
                kosten: 'Kosten',
                kostenVv: 'Kostenausweis (Ex-ante)',
                kosteninformation: {
                    title: 'Kostenangaben der Depotstelle',
                    depotentgelt: 'Depotentgelt',
                    transaktionsentgelt: 'Transaktionsentgelt',
                    transaktionsentgeltDimensional: 'Transaktionsentgelt Dimensional',
                    transaktionsentgeltETF: 'Transaktionsentgelt ETF',
                    transaktionspauschale: 'Transaktionspauschale',
                    kostenEinmalig: 'Voraussichtliche einmalige Kosten',
                    kostenFortlaufend: 'Voraussichtliche fortlaufende Kosten p.a.',
                    ausgabeAufschlag: 'Ausgabeaufschlag',
                    vermittlungsfolgeprovision: 'Vermittlungsfolgeprovision',
                    kostenGesamt: 'Gesamtkosten der Anlage bei einer Haltedauer von ',
                    kostenDurchschnittlich: 'Durchschnittliche Kosten p.a.',
                    aggregierteKosten: 'Aggregierte Kosten und Zuwendungen',
                    gesamtkosten: 'Gesamtkosten',
                    gesamtzuwendungen: 'Gesamtzuwendungen',
                    gesamtkostenPrognose: 'Gesamtkosten Prognose',
                    reduktionText: 'Die Kosten reduzieren die durchschnittliche Rendite des Investments, bezogen auf den Anlagebetrag, jährlich um:',
                    jahr1: 'Im ersten Jahr',
                    jahr3: 'Auf 3 Jahre Haltedauer',
                    jahr5: 'Auf 5 Jahre Haltedauer',
                }
            },
            investitionsmoeglichkeiten: {
                title: 'Angabe Auswahl Investitionsmoeglichkeiten',
                titleSmallDevice: 'Angabe Auswahl Investitions-moeglichkeiten',
                auswahlSektoren: 'In welche Sektoren möchten Sie investieren?'
            },
            produktauswahl: {
                title: 'Produktauswahl',
                auswahlAnlagevorschlag: 'Wählen Sie einen Anlagevorschlag',
                auswahlAnlagevorschlagTooltip: 'Anhand Ihrer Eingaben haben wir mehrere Portfolios identifizieren ' +
                    'können, die für Sie geeignet sind. Für mehr Informationen zu den einzelnen Portfolios klicken ' +
                    'Sie bitte auf das Auswahlfeld.',
                auswahlAnlagevorschlagTooltipEinzeln: 'Anhand Ihrer Eingaben haben wir folgendes Portfolio ' +
                    'identifizieren können, das für Sie geeignet ist. Für mehr Information zum Portfolio ' +
                    'klicken Sie bitte auf das Auswahlfeld.',
                nachhaltig: 'Ausschließlich nachhaltige Produkte mit ',
                nachhaltigLink: 'FNG-Zertifikat',
                nichtNachhaltig: 'Produkte aus allen Investitionssektoren',
                esgBeruecksichtigen: 'Soll das Thema Nachhaltigkeit bei der Produktauswahl berücksichtigt werden?',
                esgModalTitle: 'Berücksichtigung von ESG-Kriterien leider nicht möglich',
                esgModalBeschreibung: 'Sie haben sich dazu entschieden, das Thema Nachhaltigkeit bei der Produktauswahl' +
                    ' zu berücksichtigen. Leider kann das Thema Nachhaltigkeit aktuell nicht in unserer Online-Strecke ' +
                    'berücksichtigt werden. Wenn Sie Anlagemöglichkeiten suchen, die das Thema Nachhaltigkeit ausdrücklich ' +
                    'berücksichtigen, wenden Sie sich hierfür gerne an Ihren Finanzanlagenvermittler.\n' +
                    'Sie können die Online-Strecke weiterführen, wenn Sie sich entscheiden, das Thema Nachhaltigkeit nicht ' +
                    'gesondert bei der Produktauswahl zu berücksichtigen.',
                esgModalButton: 'Nachhaltigkeit nicht berücksichtigen',
                esgkriterien: 'ESG-Kriterien',
                auswaehlen: 'auswählen',
                produktinformationen: 'Produktinformationen',
            },
            postident: {
                title: 'Identitätsnachweis',
                weiterTitle: 'Wie geht es nach der Legitimation weiter?',
                weiter2: `
                    Sie finden Ihre Vertragsunterlagen auch bereits jetzt in Ihrer Postbox. Eine
                    E\u2011Mail mit Ihren persönlichen Zugangsdaten haben Sie bereits erhalten.
                    Sollten Sie noch keine Zugangsdaten erhalten haben, kontrollieren Sie bitte
                    auch Ihren Spam-Ordner.
                `,
                non_vv: {
                    beschreibung: `
                        Für einen erfolgreichen Vertragsabschluss sind wir gesetzlich
                        verpflichtet, uns von der Identität unseres Vertragspartners zu überzeugen.
                        Hierzu sind wir auch verpflichtet, Ihre Personalien aufzunehmen und auch mit
                        Ihnen abzugleichen. Wir verwenden dafür das POSTIDENT-System der Deutschen
                        Post.
                    `,
                    weiter1: `
                        Nach der Legitimation per POSTIDENT wird das Depot eröffnet und Sie erhalten
                        Ihre Depotunterlagen direkt von Ihrer Depotstelle übermittelt.
                    `,
                    depotEroeffnungTitle: 'Wann ist das Depot eröffnet?',
                    depotEroeffnung: `
                        Nach erfolgreicher Legitimation dauert die Einrichtung in der Regel zwischen
                        drei und fünf Werktagen.
                    `,
                },
                vv: {
                    beschreibung: `
                        Für die Einrichtung einer Vermögensverwaltung sind wir gesetzlich
                        verpflichtet, uns von der Identität unseres Vertragspartners zu überzeugen.
                        Hierzu sind wir auch verpflichtet, Ihre Personalien aufzunehmen und auch mit
                        Ihnen abzugleichen. Wir verwenden dafür das POSTIDENT-System der Deutschen
                        Post.
                    `,
                    weiterTitle: 'Wie geht es nach der Legitimation weiter?',
                    weiter1: `
                        Nach der Legitimation per POSTIDENT wird das Depot eröffnet und Sie erhalten
                        Ihre Depotunterlagen direkt von Ihrer Depotstelle übermittelt. Von uns
                        erhalten Sie in den kommenden Tagen ein Schreiben, mit dem wir Ihnen die
                        Annahme und Einrichtung der Vermögensverwaltung bestätigen.
                    `,
                    depotEroeffnungTitle: 'Wann ist das Depot inkl. Cash-Konto eröffnet?',
                    depotEroeffnung: `
                        Nach erfolgreicher Legitimation dauert die Einrichtung in der Regel zwischen
                        drei und fünf Werktagen. Die Depotunterlagen werden Ihnen von der
                        Depotstelle per Post zugeschickt. Dort finden Sie die IBAN des Cash-Kontos.
                    `,
                    kontoTitle: 'Deckung des Cash-Kontos',
                    anlagebetragUeberweisung: `
                        Bitte überweisen Sie Ihren vereinbarten Anlagebetrag in Höhe von
                        {0} nach Depoteröffnung auf das Cash-Konto der Depotstelle.
                    `,
                    anlagebetragLastschriftSofort: `
                        Sobald das Depot eröffnet ist, werden wir bei der Depotstelle für Sie einen
                        Auftrag zum SEPA-Lastschrifteinzug über {0} von Ihrer Bankverbindung
                        in das Depot einreichen. Bitte sorgen Sie für ausreichende Kontodeckung.
                    `,
                    anlagebetragLastschriftSpaeter: `
                        Zum {1}, jedoch frühestens sobald das Depot eröffnet ist, werden wir bei
                        der Depotstelle für Sie einen Auftrag zum SEPA-Lastschrifteinzug über {0}
                        von Ihrer Bankverbindung in das Depot einreichen. Bitte sorgen Sie für
                        ausreichende Kontodeckung.
                    `,
                    sparbetrag: `
                        Für Ihren Sparplan empfehlen wir, einen Dauerauftrag auf das Cash-Konto
                        mit Ihrem monatlichen Anlagebetrag in Höhe von {0} einzurichten.
                    `,
                },
                fastGeschafft: {
                    ek: 'Fast geschafft! Sobald Sie den Postident starten, wird der Online-Prozess ' +
                      'automatisch abgeschlossen.',
                    gk: 'Fast geschafft! Sobald Sie den Postident für {0} starten, wird der Online-Prozess ' +
                      'automatisch abgeschlossen.',
                },
                weiterButton: 'POSTIDENT starten',
                abbrechenButton: 'Abbrechen',
            },
            wertpapierErfahrung: {
                title: 'Erfahrungen am Kapitalmarkt',
                subtitle: 'Wie haben Sie Ihre Transaktionen bisher abgewickelt?',
                orderausfuehrung: 'Reine Orderausführung',
                orderausfuehrung_erklaerung: 'Ohne Beratung durch einen Anlageberater oder eine Bank',
                anlageberatung: 'Anlageberatung',
                anlageberatung_erklaerung: 'In Abstimmung mit einem Anlageberater oder einer Bank',
                vermoegensverwaltung: 'Vermögensverwaltung',
                vermoegensverwaltung_erklaerung: 'Anlageentscheidungen wurden von  Vermögensverwalter getroffen',

                subtitle_tatigkeit_1: 'Haben Sie in Ihrer Ausbildung, Ihrem Studium oder in Ihrer beruflichen Tätigkeit relevante',
                subtitle_tatigkeit_2: 'Erfahrungen im Bereich des Kapitalmarkts gesammelt?',

                bildung: 'Ausbildung/Studium',
                bildung_erklaerung: 'z. B. Finanzwissenschaft, Bank- und Finanzwesen, Versicherungswirtschaft oder Wirtschaftsmathematik',

                beruf: 'Beruf',
                beruf_erklaerung: 'z. B. Finanzanalyst, Wertpapierhändler, Investmentbanker, Portfoliomanager oder Vermögensberater'

            },
            createAccount: {
                title: 'Onlinezugang anlegen',
                text: 'Legen Sie einen Onlinezugang an, um die Daten für Ihre Anlage weiter ' +
                  'bearbeiten zu können.',
                smsHint: 'Sie erhalten eine SMS mit Ihrem Anmeldecode und eine E-Mail mit einem ' +
                  'Zugangslink, den Sie später jederzeit nutzen können.',
                smsHintShort: 'Sie erhalten eine SMS mit Ihrem Anmeldecode.',
                zustimmung1: {
                    ek: 'Ich stimme den Bedingungen der',
                    gk: 'Wir stimmen den Bedingungen der',
                },
                zustimmung2: 'zu',
                datenschutzerklaerung: 'Datenschutzerklärung'
            },
            confirmAccount: {
                title: 'Onlinezugang bestätigen',
                anmeldecodeErklaerung1: 'Wir haben Ihnen per SMS einen fünfstelligen Anmeldecode zugeschickt.',
                anmeldecodeErklaerung2: 'Bitte tragen Sie diesen hier ein.',
                anmeldecodeErklaerung3: 'Die Zusendung kann einige Minuten in Anspruch nehmen. Sollten Sie den Code nicht erhalten, versuchen Sie es erneut.',
                erneutSenden: 'Anmeldecode erneut zuschicken'
            },
            depotinhaber: {
                title: 'Angaben zum Depotinhaber',
                vorname_alle_angeben: 'Pflichtfeld. Bitte geben Sie alle Vornamen wie in Ihrem Ausweisdokument vollständig an.',
            },
            geburtsdaten: {
                title: 'Geburtsdaten und Nationalität',
                checkbox: 'Ich bestätige, dass ich keine US-amerikanische Staatsbürgerschaft besitze.',
                weitere: 'Weitere Staatsbürgerschaft ',
            },
            bankverbindung: {
                title: 'Referenzbankverbindung',
                text: {
                    ek: 'Über Ihre Referenzbankverbindung werden die Zahlungen für Ihre Anlage abgewickelt.\n' +
                      'Bitte geben Sie hierfür ein Konto an, dessen Inhaber Sie selbst sind.',
                    gk: 'Über Ihre Referenzbankverbindung werden die Zahlungen für Ihre Anlage abgewickelt.\n' +
                      'Bitte geben Sie hierfür ein Konto an, dessen Inhaber Sie selbst in Gemeinschaft sind.'
                },
                zahlungswegVv: {
                    description: 'Zahlungsweg',
                    lastschrift: {
                        ek: `Die depotführende Bank soll den Anlagebetrag von {0} per Lastschrift von
                           meinem genannten Konto einziehen.`,
                        gk: `Die depotführende Bank soll den Anlagebetrag von {0} per Lastschrift von
                           unserem genannten Konto einziehen.`,
                    },
                    noLastschriftPossible: 'Bis zu einem Betrag von 500.000,00 € können Sie bequem zwischen ' +
                      'Lastschrift und Überweisung wählen. Ab 500.000,01 € bitten wir Sie, Ihre Investition per ' +
                      'Banküberweisung vorzunehmen. Vielen Dank für Ihr Vertrauen!',
                    ueberweisung: {
                        ek: `Ich werde den Anlagebetrag von {0} selbst auf das zu meinem Depot gehörende
                        Abwicklungskonto nach der Depoteröffnung überweisen.`,
                        gk: `Wir werden den Anlagebetrag von {0} selbst auf das zu meinem/unserem Depot
                        gehörende Abwicklungskonto nach der Depoteröffnung überweisen.`,
                    },
                    info:
                        `Wir teilen Ihnen die Bankverbindung des Abwicklungskontos zu Ihrem Depot
                        umgehend mit, sobald die Depoteröffnung erfolgt ist.`,
                    infoSparplan: `
                        Im Rahmen der erteilten Vermögensverwaltungsvollmacht wird der Vermögensverwalter
                        Ihren Sparplan bei der Depotstelle in Höhe von {0} mittels Lastschriftverfahren einrichten lassen.
                        <strong>Bitte richten Sie keinen Dauerauftrag ein, um mögliche Doppelbuchungen zu vermeiden.</strong>
                    `,
                },
                ausfuehrungsdatum: {
                    description: 'Ausführungsdatum',
                    sofort: {
                        ek: 'Ich möchte {0} sofort nach Depoteröffnung anlegen.',
                        gk: 'Wir möchten {0} sofort nach Depoteröffnung anlegen.',
                    },
                    spaeter: {
                        ek: 'Ich möchte erst zu einem späteren Zeitpunkt {0} anlegen{1}',
                        gk: 'Wir möchten erst zu einem späteren Zeitpunkt {0} anlegen{1}',
                    },
                    zeitpunkt: `Zeitpunkt`,
                    infoSparplanNonVv: `
                        Wir erstellen für Ihren Sparplan in Höhe von {0} einen monatlichen
                        Orderauftrag mit erster Ausführung am {1}.
                    `,
                },
            },
            ausweisdaten: {
                title: 'Identitätsnachweis',
                text1: 'Bitte halten Sie das Dokument griffbereit. ',
                text2: 'Das Dokument wird in den nächsten Schritten zu Ihrer Identifikation verwendet.',
                info: 'Für den Abschluss Ihres Auftrags ist ein Identitätsnachweis erforderlich. Wir verwenden ' +
                    'dafür das POSTIDENT-System der Deutschen Post.',
                videoIdentButton: 'POSTIDENT starten',
                reisepassErforderlich: `
                    Die Legitimierung ist nur mit durch die Bundesrepublik Deutschland ausgestellten
                    Personalausweisen möglich. Bitte tragen Sie die Daten Ihres Reisepasses ein.
                `,
                persoOderReisepass: `
                    Die Legitmierung kann nur mit einem deutschen Personalausweis oder mit einem
                    Reisepass durchgeführt werden.
                `,
            },
            erfahrungen: {
                title: 'Kenntnisse & Erfahrungen zu Finanzinstrumenten',
                text: 'Zu welchen der folgenden Finanzinstrumente verfügen Sie bereits über Kenntnisse und Erfahrungen?',
                tooltip: 'Mit „Kenntnissen“ ist das theoretische Wissen über eine Anlageklasse gemeint. ' +
                    '<br/>' +
                    'Mit „Erfahrungen“, ob Sie bereits für sich oder jemand anderen in diese Anlageklasse ' +
                    'investiert haben.'
            },
            erfahrungenUmfang: {
                title: 'Umfang der Anlageerfahrungen',
                textHoehe: 'Wie hoch war der Gesamtumfang der Geschäfte durchschnittlich pro Jahr?',
                textAnzahl: 'Wie oft tätigen Sie Geschäfte durchschnittlich pro Jahr?'
            },
            finanzielleVerhaeltnisse: {
                title: 'Finanzielle Verhältnisse',
                textMonatlich: {
                    ek: 'Bitte tragen Sie die relevanten Informationen zu Ihrer monatlichen Liquidität in die entsprechenden Felder ein.',
                    gk: 'Bitte tragen Sie die relevanten Informationen zu Ihrer gemeinsamen monatlichen Liquidität in die entsprechenden Felder ein.',
                },
                textVermoegen: {
                    ek: 'Bitte tragen Sie die relevanten Informationen zu Ihrer Liquidität in die entsprechenden Felder ein.',
                    gk: 'Bitte tragen Sie die relevanten Informationen zu Ihrer gemeinsamen Liquidität in die entsprechenden Felder ein.',
                },
                monatlicheLiquiditaet: 'Monatliche Liquidität',
                freiesvermoegen: 'Freies Vermögen für Ihr Anlageziel',
                warnungEinmalanlage1: 'Wir empfehlen Ihnen einen Anlagebetrag von bis zu ',
                warnungEinmalanlage2: ' €. Damit ist sichergestellt, dass Ihnen finanzielle Reserven in der Höhe Ihrer dreifachen monatlichen Liquidität für unverhergesehene Ausgaben zur Verfügung stehen.',
                fehlerEinmalanlage: 'Der Betrag für die Einmalanlage übersteigt Ihr freies Vermögen. Bitte korrigieren Sie den Anlagebetrag. ',
                warnungSparplan1: 'Der Betrag für Ihren Sparplan übersteigt 50% der monatlichen Liquidität. Wir empfehlen Ihnen eine Sparrate von höchstens ',
                warnungSparplan2: ' € monatlich.',
                fehlerSparplan: 'Der Betrag für Ihren Sparplan übersteigt Ihre monatliche Liquidität. Bitte korrigieren Sie den Sparbetrag.',
                verlusttragfaehigkeit: {
                    ek: 'Ich bestätige, dass ich notfalls auch einen Verlust des eingesetzten Kapitals verkraften kann.',
                    gk: 'Wir bestätigen, dass wir notfalls auch einen Verlust des eingesetzten Kapitals verkraften können.'
                },
            },
            navigation: {
                weiter: 'Weiter',
                zurueck: 'Zurück',
                spaeter: 'Später',
                verlassen: 'Beratung verlassen',
                korrigieren: 'Eingabe Korrigieren',
            },
            footer: {
                kontakt: 'Sie haben Fragen? Bitte rufen Sie uns an.',
                impressum: 'Impressum',
                datenschutz: 'Datenschutz',
            },
            reusable: {
                labels: {
                    strasse: 'Straße',
                    hausnr: 'Hausnr.',
                    plz: 'Postleitzahl',
                    ort: 'Ort',
                    land: 'Land',
                    email: 'E\u2011Mail',
                    telefon: 'Telefonnummer',

                    einmaligeAnlage: 'Einmalige Anlage',
                    regelmaessigerSparbetrag: 'Monatlicher Sparbetrag',

                    einmalanlage: 'Einmalanlage',
                    sparbetrag: 'monatlicher Sparbetrag',
                    anlagezeitraum: 'Anlagezeitraum',
                    risikoneigung: 'Risikoneigung',
                    Empfohlen: 'Empfohlen: bis zu ',

                    vorname: 'Vorname',
                    nachname: 'Nachname',
                    geburtsname: 'Geburtsname',
                    mobilnummer: 'Mobilnummer',
                    passwort: 'Passwort',
                    titel: 'Titel',

                    geburtsdatum: 'Geburtsdatum',
                    geburtsort: 'Geburtsort',
                    geburtsland: 'Geburtsland',
                    nationalitaet: 'Staatsbürgerschaft',

                    anmeldecode: 'Anmeldecode',

                    kontoinhaber: 'Kontoinhaber',
                    bank: 'Bank',
                    iban: 'IBAN',
                    bic: 'BIC',

                    ausweisart: 'Ausweisart',
                    ausweisnummer: 'Ausweisnummer',
                    behoerde: 'Ausstellende Behörde',
                    ausstellungsland: 'Ausstellungsland',
                    ausgestelltAm: 'Ausgestellt am',
                    gueltigBis: 'Gültig bis',

                    geldmarkt: 'Geldmarkt- oder Geldmarktnahe Fonds',
                    festverzinslich: 'Festverzinsliche Wertpapiere / Rentenfonds',
                    immobilienfonds: 'Immobilienfonds',

                    aktienfonds: 'Aktienfonds',
                    aktienfondsErklaerung: 'Erklärung zu Aktienfonds',

                    etf: 'Exchange Traded Funds (ETF)',
                    etfErklaerung: 'Erklärung zu Exchange Traded Funds (ETF)',

                    offene_immobilienfonds: 'Offene Immobilienfonds',
                    offene_immobilienfondsErklaerung: 'Erklärung zu Offenen Immobilienfonds',

                    regelbasierte_fonds: 'Regelbasierte Fonds',
                    regelbasierte_fondsErklaerung: 'Erklärung zu Regelbasierte Fonds',

                    gemischte_fonds: 'Gemischte und sonstige Investmentfonds',
                    gemischte_fondsErklaerung: 'Erklärung zu Gemischte und sonstige Investmentfonds',

                    hedgefonds: 'Hedgefondsstrategien und Hedgefonds',
                    hedgefondsErklaerung: 'Erklärung zu Hedgefondsstrategien und Hedgefonds',

                    spezialinvestmentfonds: 'Offene Spezialinvestmentfonds',
                    spezialinvestmentfondsErklaerung: 'Erklärung zu Offene Spezialinvestmentfonds',


                    garantieprodukte: ' Garantieprodukte oder -Fonds',
                    mischfonds: 'Mischfonds',
                    aktien: 'Aktien / Aktienfonds',

                    nettoverdienst: 'Monatlicher Nettoverdienst',
                    einkommensquelle: 'Wesentliche Einkommensquelle',
                    ausgaben: 'Monatliche Ausgaben',
                    liquidesVermoegen: 'Liquides Vermögen',
                    vermoegenInKapitalanlagen: 'Vermögen in Kapitalanlagen',
                    immobilien: 'Immobilien',
                    verbindlichkeiten: 'Verbindlichkeiten',

                    entfernen: 'entfernen',
                    hinzufuegen: 'hinzufügen',
                },
                selectOptions: {
                    herr: 'Herr',
                    frau: 'Frau'
                },
                einstellungen: 'Einstellungen',
                jahre: 'Jahre',
                jahren: 'Jahren',
                nichtSicher: 'Sie sind sich nicht sicher?',
                fragebogen: 'unserem Fragebogen',
                optional: 'Optional',
                pflicht: 'Pflichtfeld',
                wennAngegeben: 'Wenn angegeben',
                euro: '€',
                depotinhaber1: '1. Person',
                depotinhaber2: '2. Person',
            },
            exception: {
                error: {
                    dea: {
                        missing: {
                            property: "fehlende Eingabe"
                        },
                        ungueltiger: {
                            wert: "ungültige Eingabe"
                        },
                        tin: {
                            repeated: "Selbe Steueridentifikationsnummer (TIN) mehrfach angegeben",
                        },
                    },
                    ak: {
                        fundbuy: {
                            trabetrag: {
                                below: {
                                    minimum: "zu niedrig"
                                },
                            },
                        },
                    },
                    fundbuy: {
                        trabetrag: {
                            outOfRange: "zu niedrig bzw. zu hoch"
                        },
                    },
                },
            },
            error: {
                notFound: 'Die Seite kann nicht gefunden werden',
                serverError: 'Internal Server Error',
                unknown: 'Unbekannter Fehler',
                error: 'Fehler',
            }
        }
    }
};
