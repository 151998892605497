
interface INameData {
  titel?: string;
  vorname: string;
  nachname: string;
}

export const getName = <D extends INameData>(data: D) => {
  const {titel, vorname, nachname} = data;
  const title = titel ? `${titel} ` : '';
  return `${title}${vorname} ${nachname}`;
};

export const annotated = (str: string, annotation?: string) => {
  const annot = annotation ? ` (${annotation})` : '';
  return str + annot;
};
