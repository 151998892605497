import { bodyIfOk } from '@/utils/http';
import Vue from 'vue';
import VueRouter, {Route, RouteConfig} from 'vue-router';
import Vuex from 'vuex';

Vue.use(Vuex);

const viewPaths = {
    'adresse': () => import('../views/daten/Adresse.vue'),
    'anlagebetrag': () => import('../views/kapitalanlage/Anlagebetrag.vue'),
    'anlagehorizont': () => import('../views/kapitalanlage/Anlagehorizont.vue'),
    'anlagevorschlag': () => import('../views/kapitalanlage/Anlagevorschlag.vue'),
    'anlageziel': () => import('../views/kapitalanlage/Anlageziel.vue'),
    'ausweisdaten': () => import('../views/daten/Ausweisdaten.vue'),
    'ausweisdatenueberpruefen': () => import('../views/abschluss/Ausweisdatenueberpruefen.vue'),
    'bankagbannahme': () => import('../views/abschluss/Confirmdepoteroeffnung.vue'),
    'bankverbindung': () => import('../views/daten/Bankverbindung.vue'),
    'confirmaccount': () => import('../views/daten/ConfirmAccount.vue'),
    'createaccount': () => import('../views/daten/CreateAccount.vue'),
    'depoteroeffnungsantragannahme': () => import('../views/abschluss/DepotEroeffnen.vue'),
    'depotinhaber': () => import('../views/daten/Depotinhaber.vue'),
    'dienstleistung': () => import('../views/kapitalanlage/Dienstleistung.vue'),
    'erfahrunggattung': () => import('../views/kapitalanlage/ErfahrungGattung.vue'),
    'erfahrungquelle': () => import('../views/kapitalanlage/ErfahrungQuelle.vue'),
    'erfahrungumfang': () => import('../views/kapitalanlage/ErfahrungUmfang.vue'),
    'freiesvermoegen': () => import('../views/kapitalanlage/FreiesVermoegen.vue'),
    'gwgabfrage': () => import('../views/abschluss/GwgAbfrage.vue'),
    'herkunft': () => import('../views/daten/Herkunft.vue'),
    'kundentyp': () => import('../views/kapitalanlage/Kundentyp.vue'),
    'monatlicheliquiditaet': () => import('../views/kapitalanlage/MonatlicheLiquiditaet.vue'),
    'produktauswahl': () => import('../views/kapitalanlage/Produktauswahl.vue'),
    'risikobereitschaft': () => import('../views/kapitalanlage/Risikobereitschaft.vue'),
    'steuer': () => import('../views/daten/Steuer.vue'),
    'vertragannahme': () => import('../views/abschluss/Confirmanlageberatung.vue'),
    'zusammenfassungannahme': () => import('../views/abschluss/ZusammenfassungAnnahme.vue'),
};

const backendRouteData: Map<string, string> = await fetch("/api/frontend-route-data").then(bodyIfOk);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        props: true,
        component: () => import('../views/Home.vue'),
    },
    {
        // no component, no redirect – path is handled in App.vue
        path: '/vp/:vpKundenNr',
        name: 'InitVpSessionByKundenNr',
    },
    {
        // no component, no redirect – path is handled in App.vue
        path: '/vp/:vpKundenNr/kunde/:kundenId',
        name: 'InitVpSessionByKundenNrAndKundenId',
    },
    {
        path: '/bestandskunde',
        name: 'bestandskunde',
        props: true,
        component: () => import('../views/Bestandskunde.vue'),
    },
    {
        path: '/bestandskundeConfirm',
        name: 'bestandskundeConfirm',
        props: true,
        component: () => import('../views/daten/ConfirmAccount.vue'),
    },
    {
        path: '/beratung',
        component: () => import('../views/Beratung.vue'),
        props: true,
        // TODO: check why used as children but the path is not nested when using /
        children: [
            ...Object.entries(backendRouteData).map(
                ([path, name]) => ({
                    path,
                    name: `${name} (${path})`,
                    component: viewPaths[name.toLowerCase()],
                })
            ),
            {
                path: '/postidentLater',
                name: 'postidentLater',
                component: () => import('../views/abschluss/postidentLater.vue')
            },
            /* Spezielle Seiten (error, info, ...)*/
            {
                path: '/fehler',
                name: 'Fehler',
                component: () => import('../views/error/Fehler.vue')
            },
            {
                path: '/beratungsende',
                name: 'Beratungsende',
                component: () => import('../views/error/Beratungsende.vue')
            },
            {
                path: '/inbearbeitung',
                name: 'InBearbeitung',
                component: () => import('../views/error/InBearbeitung.vue')
            },
            {
                path: '/fehlerEingabe',
                name: 'FehlerEingabe',
                component: () => import('../views/error/FehlerEingabe.vue')
            },
            {
                path: '/geeignetheitserklaerung',
                name: 'Geeignetheitserklaerung',
                component: () => import(/* webpackChunkName: "geeignetheitserklaerung" */ '../views/Geeignetheitserklaerung.vue')
            },
            {
                path: '/',
                redirect: '/anlageziel'
            },
            {
                path: '/*',
                name: 'Error',
                component: () => import('../views/error/Error.vue')
            },
        ]
    },
];

export const routerStore = new Vuex.Store({
    state: {
        routerHistory: [] as Route[],
    },
    getters: {
        previousRoute: (state) => {
            const historyLen = state.routerHistory.length;
            if (historyLen === 0) return null;
            return state.routerHistory[historyLen - 1];
        },
        currentRoute: state => state.routerHistory[state.routerHistory.length],
    },
});

export const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        const fromHistory = Boolean(savedPosition);

        if (fromHistory && routerStore.state.routerHistory.length > 0) {
            routerStore.state.routerHistory.splice(-1, 1);
        } else {
            routerStore.state.routerHistory.push(from);
        }

        return savedPosition || {x: 0, y: 0};
    },
});
