<template>
    <v-app style="background-color: var(--marvin-col-lightgrey);">
        <div class="header-wrapper">
            <template v-if="!hasCookieConsentCookie">
                <v-row align="center" style="display: none;"></v-row><!--loads css rules -->
                <v-alert type="info" class="mb-0">
                    <cookie-consent class="row align-center">
                        <template v-slot:message>
                            <div class="col grow">
                                {{$t("message.landingPage.cookiesText")}}
                                <download-form-widget
                                    :jwt="jwt"
                                    target-document="datenschutz"
                                    type="pdf"
                                    style="display: inline-block;"
                                    :color="alertLinkColor"
                                >
                                    {{ $t("message.landingPage.datenschutzerklaerung") }}
                                </download-form-widget>.
                            </div>
                        </template>
                        <template v-slot:button>
                            <div class="col shrink">
                                <v-btn @click="setCookieConsentCookie()">
                                    {{$t("message.landingPage.akzeptieren")}}
                                </v-btn>
                            </div>
                        </template>
                    </cookie-consent>
                </v-alert>
            </template>

            <v-app-bar
                dense
                elevation="1"
                color="var(--marvin-col-darkgrey)"
            >
                <v-img src="/data/logo" max-height="27px" max-width="200" position="left" contain class="ml-2"/>

                <v-spacer></v-spacer>

                <div>
                    <v-avatar color="primary" size="40px">
                        <v-img alt="Vermittler" src="/data/photo"/>
                    </v-avatar>

                    <span class="d-none d-sm-inline pl-2" style="color: var(--marvin-col-white);">
                        Sie haben Fragen?
                    </span>

                    <v-btn
                        text
                        :color="phoneColor"
                        v-if="'' !== phone"
                        :href="'tel:' + phone"
                    >
                        <v-icon medium>{{icons.mdiPhone}}</v-icon>
                        <span style="font-weight: bold;" class="d-none d-md-inline">{{phone}}</span>
                    </v-btn>
                </div>
            </v-app-bar>

            <v-alert type="error" dense
                     v-if="appLevelError"
                     :icon="icons.mdiAlertCircle">
              {{appLevelError}}
            </v-alert>
        </div>

        <v-main class="pt-0">
            <keep-alive>
                <router-view v-if="!error && loaded"/>
            </keep-alive>
            <Error v-if="error" :errorMessage="error"/>
            <loading-overlay v-if="!loaded"/>
        </v-main>

        <robo-footer
            v-if="loaded"
            :phone="phone"
            :jwt="jwt"
        ></robo-footer>
    </v-app>
</template>

<style lang="scss">
@import "./scss/app.scss";
@import "./scss/form.scss";
</style>

<style lang="scss">
:root {
    //--marvin-col: var(--foo-col-4);
    --marvin-col: #00B0F0;
    --marvin-col-darkgrey: #252525;
    --marvin-col-grey: #444344;
    --marvin-col-soft-grey: rgba(0, 0, 0, .2);
    --marvin-col-lighter-grey: #565656;
    --marvin-col-lightgrey: #DFE6E9;
    --marvin-col-white: #fff;
    --marvin-col-black: #000;

    //--foo-col-4: hsl(214 70% 49%);
    --foo-col-4: var(--marvin-col) !important;
    --foo-col-6: var(--marvin-col) !important;
    --font-color-on-wl: var(--marvin-col-white);
    --font-color-outlined: var(--marvin-col);
    --font-color-black: var(--marvin-col-black);
    --font-color-grey: var(--marvin-col-grey);
    --v-primary-base: var(--marvin-col) !important;
    --v-info-base: var(--marvin-col) !important;
    //--foo-col-main: var(--marvin-col) !important;
    --marvin-col: var(--foo-col-main) !important;
}
</style>

<script>
import Vue from "vue";
import {router} from "@/router";
import { mdiPhone, mdiAlertCircle } from "@mdi/js";
import RoboFooter from "./widgets/RoboFooter.vue";
import Error from "./views/error/Error.vue";
import LoadingOverlay from "./widgets/LoadingOverlay.vue";
import DownloadFormWidget from "@/widgets/DownloadFormWidget.vue";
import CookieConsent from "vue-cookieconsent-component";
import {getSavedData, getCookieConsentCookie, getJwt, bodyIfOk, setCookieConsentCookie, removeJwt, onJwtRemoved, getInitPageAndDienstleistungPromise} from "./utils/http";
import {checkForJwt} from "./utils/jwt";
import {TextReadableCheck} from "@/utils/colors";
import {getName} from "@/utils/name";

export default Vue.extend({
    components: {
        RoboFooter,
        DownloadFormWidget,
        Error,
        LoadingOverlay,
        CookieConsent,
    },
    name: "App",
    data() {
        return {
            icons: {
                mdiPhone,
                mdiAlertCircle,
            },
            phone: '',
            phoneColor: 'white',
            alertLinkColor: 'var(--marvin-col-darkgrey)',
            favIcon: '',
            error: null,
            appLevelError: null,
            loaded: false,
            hasCookieConsentCookie: false,
            jwt: '',
        };
    },
    mounted: async function () {
        this.hasCookieConsentCookie = !!getCookieConsentCookie();

        addEventListener('storage', onJwtRemoved);

        Vue.prototype.$emitter.on('submitBegin', () => {
            this.appLevelError = null;
            this.loaded = false;
        });
        Vue.prototype.$emitter.on('submitSuccess', () => {
            this.loaded = true;
        });
        Vue.prototype.$emitter.on('submitError', event => {
            this.appLevelError = event;
            this.loaded = true;
        });

        let targetVpNr = null;
        let kundeId = null;

        switch (this.$route.name) {
            case "InitVpSessionByKundenNrAndKundenId":
                kundeId = this.$route.params.kundenId;
                // Fall through.
            case "InitVpSessionByKundenNr":
                targetVpNr = this.$route.params.vpKundenNr;
                break;
        }

        await checkForJwt(this.removeQueryParams, targetVpNr, kundeId)
            .then(() => this.loadVpData())
            .then(() => getSavedData("/data/last-visited"))
            .then(res => res.json())
            .then(url => {
                if (!url && kundeId > 0) {
                    url = "/bestandskunde?ref=" + btoa(window.location.href)
                } else if (!url || url.startsWith("/fehler")) {
                    url = "/";
                }
                if (url !== location.pathname) {
                    return router.replace({path: url});
                }
            })
            .then(() => {
                this.jwt = this.getJwt();
                // Jede 5 Minuten ein JWT-refresh machen
                const MINUTE = 60 * 1000;
                setInterval(() => checkForJwt(this.removeQueryParams), 5 * MINUTE);
            })
            .catch(e => {
              console.error(e);
              this.error = e.toString();
              removeJwt();
            })
            .finally(() => this.loaded = true);
    },
    methods: {
        getJwt: () => getJwt(),
        setCookieConsentCookie: function() {
            setCookieConsentCookie();
            this.hasCookieConsentCookie = true;
        },
        removeQueryParams() {
            const loc = new URL(location.href);

            if (!loc.search) {
                return Promise.resolve();
            }

            loc.search = "";
            router.replace(loc);
        },
        getCssVariableValue(cssVarName) {
          return getComputedStyle(document.documentElement)
              .getPropertyValue(cssVarName)
              .replaceAll(' ', '');
        },
        async loadVpData() {
            const self = this;

            const phonePromise = getSavedData("/data/vp-phone")
                .then(bodyIfOk)
                .then(res => {
                    self.phone = res ? res : '';
                })
                .catch((e) => console.error('Failed to load Phone', e));

            const stammdatenPromise = getSavedData("/data/stammdaten-vermittler")
                .then(bodyIfOk)
                .then(res => {
                    self.$store.commit("setStammdatenVermittler", res);
                    if (res.robo_name) {
                        document.title = res.robo_name;
                    }
                });

            const investmentConstraintsPromise = getSavedData("/data/constraints/general")
                .then(bodyIfOk)
                .then(res => self.$store.commit("setInvestmentConstraints", res));

            const initSettingsPromise = getSavedData("/data/settings")
                .then(bodyIfOk)
                .then(res => self.$store.commit("setSettings", res));

            const initKundentyp = getSavedData("/pages/kundentyp")
                .then(bodyIfOk)
                .then(({gemeinschaftskunde}) => {
                    self.$store.commit("setIsGemeinschaftskunde", gemeinschaftskunde);

                    if (gemeinschaftskunde) {
                        return Promise.all(
                            [1, 2].map(person => {
                                return getSavedData("/pages/depotinhaber" + (2 === person ? '-2' : ''))
                                    .then(bodyIfOk)
                                    .then(res => {
                                      if (!res?.vorname || !res.nachname) return;
                                      self.$store.commit(`setGemeinschaftskunde${person}Name`, getName(res));
                                    });
                            })
                        );
                    }
                })

            let file = document.createElement('link');
            file.rel = 'stylesheet';
            file.href = "/data/css";
            document.head.appendChild(file);

            await Promise.all([
                phonePromise,
                stammdatenPromise,
                investmentConstraintsPromise,
                getInitPageAndDienstleistungPromise(self),
                initSettingsPromise,
                initKundentyp,
            ]);

            this.setColorsAndIcons();
        },
        setColorsAndIcons() {
            const whitelabelColor = this.getCssVariableValue('--foo-col-main');
            const darkGreyColor = this.getCssVariableValue('--marvin-col-darkgrey');
            const whiteColor = this.getCssVariableValue('--marvin-col-white');
            const headerBgColor = darkGreyColor;
            const isWhiteReadable = new TextReadableCheck(whitelabelColor, '#FFF').isReadableLargeScale;

            this.$vuetify.theme.themes.light.primary.base = whitelabelColor;
            this.phoneColor = new TextReadableCheck(whitelabelColor, headerBgColor).isReadableAA ?
                'primary' : whiteColor;
            this.alertLinkColor = new TextReadableCheck(darkGreyColor, whitelabelColor).isReadableAA ?
                darkGreyColor : whiteColor;

            if (!isWhiteReadable) {
              document.body.classList
                  .add('light-wl');
              document.documentElement.style
                  .setProperty('--font-color-on-wl', 'var(--marvin-col-black)');
            }

            this.favIcon = `<?xml version="1.0" encoding="UTF-8"?>
              <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
              <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="48px" height="48px" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 10240 10240" xmlns:xlink="http://www.w3.org/1999/xlink"><path id="curve3" class="color" d="M6190 2030l-2793 2793 -842 2235c-47,123 -23,254 70,347 93,93 224,116 347,70l2235 -842 2793 -2793 -1810 -1810zm-1231 4355l-1315 495 -494 -494 495 -1315 1314 1314zm1457 -3224l0 0c125,125 125,328 0,453l-1435 1435c-125,125 -328,125 -453,0l0 0c-124,-125 -124,-328 0,-452l1436 -1436c124,-124 328,-124 452,0z"/>
                <style>
                  path {
                    fill: black;
                  }

                  path.color {
                    fill: ${whitelabelColor};
                  }

                  @media (prefers-color-scheme: dark) {
                    path {
                        fill: lightgrey;
                      }
                  }
                </style>

                <path id="curve2" class="color" d="M9358 1577l-905 -905c-250,-249 -656,-249 -906,0l-905 905 1811 1810 905 -905c249,-249 249,-656 0,-905zm-1132 -226c125,125 125,328 0,453 -125,125 -327,125 -452,0 -125,-125 -125,-328 0,-453 125,-125 327,-125 452,0z"/>
                <path id="curve1" fill="#000000" d="M8320 3707l0 0 -640 -640 0 0 640 640zm0 266l0 4987c0,353 -287,640 -640,640l-6400 0c-353,0 -640,-287 -640,-640l0 -6720c0,-353 287,-640 640,-640l0 640 0 1 0 0 0 6718c0,1 0,1 1,1l6398 0c1,0 1,0 1,-1l0 -4346 640 -640z"/>
                <path id="curve0" fill="#000000" d="M6720 640l320 0c27,0 53,3 78,10l-718 717 0 -407c0,-176 144,-320 320,-320zm432 1900l0 0 -752 -753 0 0 752 753zm-5232 -1900l320 0c176,0 320,144 320,320l0 1280c0,176 -144,320 -320,320l-320 0c-176,0 -320,-144 -320,-320l0 -1280c0,-176 144,-320 320,-320zm1600 0l320 0c176,0 320,144 320,320l0 1280c0,176 -144,320 -320,320l-320 0c-176,0 -320,-144 -320,-320l0 -1280c0,-176 144,-320 320,-320zm1600 0l320 0c176,0 320,144 320,320l0 1047 -553 553 -87 0c-176,0 -320,-144 -320,-320l0 -1280c0,-176 144,-320 320,-320z"/>
              </svg>
            `;
            const favicon = document.getElementById("favicon");
            favicon.href = 'data:image/svg+xml;base64,' + btoa(this.favIcon);
        },
    },
    computed: {
        localAttrs() {
            const attrs = {}
            attrs.absolute = false
            attrs.fixed = true
            return attrs
        },
    }
});
</script>
