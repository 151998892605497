import IBAN from 'iban';
import {i18n} from '../main';
import { FormFieldObject, Value } from '@/utils/form';

export const validate = function (formData: FormFieldObject) {
    return Object.keys(formData).some((key) => {
        return (formData[key].rules ?? []).some((rule) => {
            return 'function' === typeof rule ?
              rule(formData[key].value) !== true :
              rule;
        });
    });
};

/**
 * The input field will enter an error state if the
 * function returns false or is a string.
 * */
export type ValidationFn<V = Value> = (value: V) => boolean | string;

export type ValidationRule<V = Value> = boolean | string | ValidationFn<V>;

export const validateRequired: ValidationFn<any> = value => {
    let valid: boolean;

    switch (typeof value) {
        case 'string':
            valid = !!value;
            break;
        case 'number':
            valid = value !== null && value !== undefined;
            break;
        case 'boolean':
            valid = !!value;
            break;
        case 'object':
            if (Array.isArray(value) && value.length > 0) {
                valid = !!value;
            } else {
                valid = false;
            }
            break;
        default:
            valid = false;
            break;
    }
    return valid || 'Pflichtfeld';
}

export const validateEmail: ValidationFn<string> = value => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(value) || 'Ungültige Email'
};

export const validateIBAN: ValidationFn<string> = value => IBAN.isValid(value) || 'Ungültige IBAN';

export const validateMaxLength = (max: number): ValidationFn<string> => {
    return value => value.length <= max || `Maximal ${max} Zeichen`;
};

export const validateMinLength = (min: number): ValidationFn<string> => {
    return value => value.length >= min || `Mindestens ${min} Zeichen`;
};

export const validateMaxDate = (max: string): ValidationFn<string> => {
    return value => {
        if (!value) {
            return true;
        }
        const maxDate = typeof max === 'string' ? new Date(max) : max;
        return new Date(value) <= maxDate || `Spätestmögliches Datum: ${i18n.d(maxDate)}`
    }
};

export const validateMinDate = (min: string | Date): ValidationFn<string> => {
    return value => {
        if (!value) {
            return true;
        }
        const minDate = typeof min === 'string' ? new Date(min) : min;
        return new Date(value) >= minDate || `Frühestmögliches Datum: ${i18n.d(minDate)}`
    }
};
