import Vue from "vue";
import * as lodash from "lodash";
import { FormFieldObject, Value } from '@/utils/form';

export interface ValueFields {
  [key: string]: Value;
}

export const store = Vue.observable({
  savedValue: {} as ValueFields
});

export const setValueField = (value: any, key: string, data: any) => {
  if (data[key]) {
    data[key].value = value;
  }
}

export const setValueArray = (value: any[] | null, key: string, data: any) => {
  if (data[key] && data[key][0] && value) {
    const resultArray = [] as any[];
    value.forEach(val => {
      const defaultValue = lodash.cloneDeep(data[key][0]);
      if (Object.keys(val).length) {
        Object.keys(val).forEach((key) => {
          setValueField(val[key], key, defaultValue);
        });
        resultArray.push(defaultValue);
      }
    })
    data[key] = resultArray;
  }
}

export const extractData = (data: FormFieldObject) => {
  const extractedData = {} as ValueFields;
  Object.keys(data).forEach(key => {
    extractedData[key] = data[key].value !== '' || data[key].noNullReplacement ? data[key].value : null;
  })
  return extractedData;
}

export const extractDataFromArray = (data: FormFieldObject[]) => {
  const extractedData = [] as ValueFields[];
  data.forEach(formGroup => extractedData.push(extractData(formGroup)));
  return extractedData;
}

export const mutations = {
  setSavedValue(savedValue: ValueFields | ValueFields[], url?: string | null) {
    if (Array.isArray(savedValue) && url) {
      store.savedValue = {};
      store.savedValue[url.replace('/', '')] = savedValue as any;
    } else {
      store.savedValue = savedValue as ValueFields;
    }
  }
};
